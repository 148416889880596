import {useJobContext, useNotificationContext} from 'contexts';
import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useConfirmationDialog} from 'shared';
import {CBCButton} from 'shared/helpers';
import {PartialJob} from 'shared/types/PartialJob';

interface CancelButtonProps {
    label?: string;
    to?: string;
    title?: string;
    message?: string;
    okLabel?: string;
    cancelLabel?: string;
    noDialog?: boolean;
}

export const CancelButton = ({
    to,
    label = 'Cancel',
    title = 'Are you sure you want to exit?',
    message = 'Your progress so far has not been saved',
    okLabel = 'Yes',
    cancelLabel = 'No',
    noDialog = false,
}: CancelButtonProps) => {
    const {job, room} = useJobContext() as PartialJob;
    const {addMessages, MESSAGE_TYPES} = useNotificationContext();
    const navigate = useNavigate();
    const {dialog, showDialog} = useConfirmationDialog();

    const redirect = useCallback(() => {
        const {displayId: jobId} = job;
        const {id: roomId} = room;

        // clear errors before redirecting
        addMessages({
            type: MESSAGE_TYPES.ERROR,
            messages: [],
        });

        if (to) {
            let url = to;
            if (jobId) url = url.replace(':jobId', String(jobId));

            if (roomId) url = url.replace(':roomId', String(roomId));

            navigate(url !== to ? url : to);
        } else {
            // Default to the dashboard if no to link is provided
            if (jobId && roomId) {
                to = `/v2/job/${jobId}/room/${roomId}/dashboard`;
            } else if (jobId && typeof roomId === 'undefined') {
                to = `/v2/job/${jobId}/dashboard`;
            } else {
                to = `/v2`;
            }

            navigate(to);
        }
    }, [to, job, room]);

    const handleCancelClick = useCallback(() => {
        if (noDialog) {
            redirect();
            return;
        }

        showDialog({
            title,
            message,
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    show: true,
                    name: okLabel,
                    variant: 'danger',
                    controlledHideDialog: true,
                    action: redirect,
                },
                {
                    show: true,
                    name: cancelLabel,
                },
            ],
        });
    }, [
        to,
        job,
        room,
        title,
        message,
        okLabel,
        cancelLabel,
        noDialog,
        redirect,
    ]);

    return (
        <>
            <CBCButton
                onClick={handleCancelClick}
                iconName="Button-Cancel.svg"
                className="job-button button-light"
                style={{color: '#8a8080'}}>
                {label}
            </CBCButton>
            {dialog}
        </>
    );
};
