import {DateTime, Format} from '@cabinetsbycomputer/datetime';
import React from 'react';
import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';
import styled from 'styled-components';

// probably move this and datepicker field to a monorepo just like luxon

export const DatePickerHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
    return (
        <Container>
            {!prevMonthButtonDisabled ? (
                <button
                    type="button"
                    aria-label="Previous Month"
                    className={
                        'react-datepicker__navigation react-datepicker__navigation--previous'
                    }
                    onClick={decreaseMonth}>
                    <span
                        className={
                            'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                        }>
                        {'<'}
                    </span>
                </button>
            ) : null}
            {DateTime.fromDate(date).format(Format.MonthAndYear).toString()}
            <button
                type="button"
                aria-label="Next Month"
                className={
                    'react-datepicker__navigation react-datepicker__navigation--next'
                }
                onClick={increaseMonth}>
                <span
                    className={
                        'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                    }>
                    {'>'}
                </span>
            </button>
        </Container>
    );
};

const Container = styled.strong`
    font-size: 1rem;
`;
