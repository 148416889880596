export const JobStatus: {
    [key: number]: string;
} = {
    0: 'Processing',
    1: 'Pending Approval',
    2: 'Pending Payment',
    3: 'In Production',
    4: 'Job Completed',
    5: 'Pending Confirmation',
    6: 'Pending Approval',
    7: 'Customer Approved',
    11: 'GFP Processing',
    12: 'GFP Submitted',
    13: 'GFP Marked As Paid',
    14: 'GFP Payment Received',
    15: 'GFP Delivered',
    16: 'Awaiting in Store Payment',
    17: 'Awaiting Direct Debit Payment',
    18: 'Awaiting Approval',
    19: 'GFP Processing',
    20: 'GFP Submitted',
    21: 'GFP Marked As Paid',
    22: 'GFP Payment Received',
    23: 'GFP Delivered',
    24: 'Awaiting in Store Payment',
    25: 'Awaiting Direct Debit Payment',
    26: 'Awaiting Approval',
    27: 'Awaiting Manufacturer Approval',
    28: 'Manufacturer Awaiting Payment',
    29: 'GFP In Production',
    30: 'Awaiting Supplier Delivery Date Confirmation',
    31: 'Delivery Date Confirmed',
    32: 'Awaiting Supplier Production Completion Confirmation',
    33: 'Awaiting Manufacturer Freight Cost',
    34: 'Awaiting Supplier Freight Cost Approval',
    35: 'DELETED',
};

export enum JobStatusEnum {
    PROCESSING = 0,
    GRP_PROCESSING = 11,
    GRP_SUPPLIER_PROCESSING = 19,
    PENDING_APPROVAL = 1,
    PENDING_PAYMENT = 2,
    PRODUCTION = 3,
    COMPLETED = 4,
    PENDING_CONFIRMATION = 5,
    CUSTOMER_APPROVED = 7,
}

export const JobStatusDefaults = [
    JobStatusEnum.PROCESSING,
    JobStatusEnum.PENDING_APPROVAL,
    JobStatusEnum.PENDING_PAYMENT,
    JobStatusEnum.PRODUCTION,
    JobStatusEnum.COMPLETED,
    JobStatusEnum.PENDING_CONFIRMATION,
    JobStatusEnum.CUSTOMER_APPROVED,
];

export const JobStatusDefaultsSimplified = [
    JobStatusEnum.PROCESSING,
    JobStatusEnum.COMPLETED,
];

export const JobStatusFilter: Array<{
    id: string;
    url: string;
    status: number;
    text?: string;
}> = [
    {
        id: 'gcprocessing',
        url: 'processing',
        status: JobStatusEnum.PROCESSING,
    },
    {
        id: 'gcpendingapproval',
        url: 'pending-approval',
        status: JobStatusEnum.PENDING_APPROVAL,
    },
    {
        id: 'gccustomerapproved',
        url: 'customer-approved-jobs',
        status: JobStatusEnum.CUSTOMER_APPROVED,
    },
    {
        id: 'gcpendingpayment',
        url: 'pending-payment',
        status: JobStatusEnum.PENDING_PAYMENT,
    },
    {
        id: 'gcproduction',
        url: 'production',
        status: JobStatusEnum.PRODUCTION,
    },
    {
        id: 'pendingconfirmation',
        url: 'pending-confirmation-jobs',
        status: JobStatusEnum.PENDING_CONFIRMATION,
    },
    {
        id: 'gcjobcompleted',
        url: 'job-completed',
        status: JobStatusEnum.COMPLETED,
    },
];

export const DashboardStatusDefaults: {
    [key: number]: string;
} = {
    [JobStatusEnum.PROCESSING]: 'Quoted Jobs',
    [JobStatusEnum.PENDING_APPROVAL]: 'Pending Approval Jobs',
    [JobStatusEnum.PENDING_PAYMENT]: 'Pending Payment Jobs',
    [JobStatusEnum.PRODUCTION]: 'Production Jobs',
    [JobStatusEnum.PENDING_CONFIRMATION]: 'Pending Confirmation Jobs',
    [JobStatusEnum.COMPLETED]: 'Completed Jobs',
};
